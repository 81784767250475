@import '../../theme/variables';
@import '../../theme/snippets';
@import '../../theme/mixins';

.container {
  @extend %customerPageContainer;
}

.content {
  @extend %customerPageContent;
}

.gearTabs {
  width: 100%;
}

.gearTabContent {
  @include verticalFlexGap(1.5rem);
}

.forMobileOnly {
  display: none;
}

.myGearTabItemWrapper {
  width: 100%;
  height: 100%;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;
  overflow: hidden;
}

.filters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.myGearTabItem {
  position: relative;
  width: 100%;
  height: 96px;
  display: grid;
  grid-template-columns: 96px auto 172px;

  .gearImage {
    position: relative;
    width: 96px;
    height: 96px;
  }

  .gearImageCover {
    width: 100%;
    height: 100%;
    color: $neutral-white;
    background-color: $slider-cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gearIconWrapper {
    height: 100%;
    width: 100%;
    border: 1px dashed $primary-color-02;
    background-color: $neutral-gray;
    border-radius: 8px 0 0 8px;
    @extend %centred-content;
  }

  .myGearTabItemContent {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .myGearTabItemTitle {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
    }

    h4 {
      @extend %ellipsis-text;
      max-width: 735px;
      margin: 0;
    }

    p {
      color: $secondary-color-02;
    }

    .myGearPublishButton {
      width: 110px;
      padding: 4px 16px;
      background-color: $primary-color-00;
      border-radius: 8px;
      color: white;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: $primary-color-01;
      }
    }

    .myGearPublishButtonMobile {
      display: none;
    }
  }

  .myGearTabItemActions {
    display: flex;
    padding-right: 1.5rem;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    .myGearPublishButton {
      width: 110px;
      padding: 4px 16px;
      background-color: $primary-color-00;
      border-radius: 8px;
      color: white;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: $primary-color-01;
      }
    }
  }
}

.modalButtons {
  display: flex;
  justify-content: center;
  gap: 24px;
  button {
    width: 228px;
  }
}

// BREAKPOINTS
@media (max-width: 1072px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 500px;
      }
    }
  }
}

@media (max-width: 932px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 400px;
      }
    }
  }
}

@media (max-width: 832px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: $tablets-width) {
  .myGearTabItemWrapper {
    @include verticalFlexGap(1rem);
    padding: 1rem;
  }

  .myGearTabItem {
    height: max-content;

    .gearImage {
      border-radius: 8px;
      overflow: hidden;
    }

    .myGearTabItemContent {
      h4 {
        max-width: 400px;
      }

      .myGearPublishButtonDesktop {
        display: none;
      }
      .myGearPublishButtonMobile {
        display: block;
        margin-top: .5rem;
      }
    }
  }
  .notForMobiles {
    display: none !important;
  }

  .forMobileOnly {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 640px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 544px) {
  .container {
    padding: 40px 24px;
  }
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 250px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 200px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .myGearTabItem {
    .myGearTabItemContent {
      h4 {
        max-width: 150px;
      }
    }
  }
}

@media screen and (max-width: $mobiles-width) {
  .modalButtons {
    flex-direction: column;
  }
}
